import React from 'react';
import { observer } from 'mobx-react-lite';

export default observer(function PaymentProcess() {

    return (
        <>
            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 w-xl-50">
                <div className="d-flex flex-column gap-7 gap-lg-10 w-100">
                    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                        {/*begin::Card header*/}
                        <div className="card-header">
                            <div className="card-title">
                                <h2>Zapłać za swoje zakupy - Szybkie Przelewy</h2>
                            </div>
                            <div className="text-muted fw-bold fs-6">W tym miejscu odbywa się płatność. Jako, że jesteś na demo widzisz ten napis. Tu podpięte jest wprowadzaniu kodu Blik lub przekierowanie do Przelewy 24 
                            </div>
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-0">
                            <div className="row mt-10">
                                {/*begin::Input group*/}
                                <div className="mb-0 fv-row d-flex justify-content-center">
                                    <img src="/assets/media/illustrations/sigma-1/6.png" alt="" className="mw-100 h-200px h-sm-325px" />
                                </div>
                                {/*end::Input group*/}
                            </div>
                        </div>
                        {/*end::Card body*/}
                    </div>
                </div>
            </div>
        </>
    )
})
