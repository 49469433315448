import React from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import urls, { RedirectTargets } from '../common/urls';

export default observer(function Footer() {
    return (
        <>
            {/*begin::Footer*/}
            <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                {/*begin::Container*/}
                <div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
                    {/*begin::Copyright*/}
                    <div className="text-light order-2 order-md-1">
                        <span className="text-light-200 fw-bold me-1">2022©</span>
                        <a href="https://www.basketbot.live" target="_blank" rel="noreferrer" className="text-light-800 text-hover-primary">BasketBot.live </a>
                        | powered by <a href="https://www.skyhighcode.com" target="_blank" rel="noreferrer" className="text-light-800 text-hover-primary">Sky High Code</a>
                    </div>
                    {/*end::Copyright*/}
                    {/*begin::Menu*/}
                    <ul className="menu menu-light-700 menu-hover-primary fw-bold order-1">
                        <li className="menu-item">
                            <NavLink to={urls.redirect(RedirectTargets.channel)} className="menu-link px-2">Facebook</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to={urls.redirect(RedirectTargets.terms)} className="menu-link px-2">Regulamin</NavLink>
                        </li>
                    </ul>
                    {/*end::Menu*/}
                </div>
                {/*end::Container*/}
            </div>
            {/*end::Footer*/}
        </>
    )
})