import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import BasketPage from '../../features/checkout/BasketPage';
import RedirectPage from '../../features/checkout/RedirectPage';
import FlexPage from '../../features/playground/FlexPage';
import urls from '../common/urls';
import Footer from './Footer';
import Header from './Header';

function App() {
  return (
    <Suspense fallback="loading">
      {/*begin::Page*/}
      <div className="page d-flex flex-row flex-column-fluid">
        {/*begin::Wrapper*/}
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          {/*begin::Container*/}
          <div id="kt_content_container" className="d-flex flex-column-fluid container-xxl flex-center">
            <Route exact path={urls.root()} component={BasketPage} />
            <Route exact path={'/flex'} component={FlexPage} />
            <Route exact path={urls.redirectPattern()} component={RedirectPage} />
          </div>
          {/*end::Container*/}
          <Footer />
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::Page*/}
    </Suspense >
  );
}
export default App;
