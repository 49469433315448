import React from 'react';
import { observer } from 'mobx-react-lite';
export default observer(function FlexPage() {
    return (
        <>
            {/* <div className="d-flex flex-root flex-row h-800px">
                <div className="d-flex flex-column flex-row-auto w-200px">
                    <div className="d-flex flex-column-auto h-50px bg-primary">
                        <span className="text-white">Fixed Height</span>
                    </div>
                    <div className="d-flex flex-column-fluid bg-success flex-center">
                        <span className="text-white">Fluid Height</span>
                    </div>
                </div>
                <div className="d-flex flex-column flex-row-fluid">
                    <div className="d-flex flex-column-auto h-70px bg-info flex-center">
                        <span className="text-white">Fixed Height</span>
                    </div>
                    <div className="d-flex flex-row flex-column-fluid">
                        <div className="d-flex flex-row-fluid bg-dark flex-center">
                            <span className="text-white">Fluid Width</span>
                        </div>
                        <div className="d-flex flex-row-auto w-200px bg-warning flex-center">
                            <span className="text-white">Fixed Width</span>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="d-flex flex-root flex-column flex-xl-column h-800px flex-center">
                <div className="d-flex flex-column flex-xl-row h-100 w-75">
                    <div className="d-flex flex-column flex-xl-column h-100 w-100">
                        <button className='h-100 w-100'>widget1</button>
                        <button className='h-100 w-100'>widget2</button>
                        <button className='h-100 w-100'>widget3</button>
                    </div>
                    <div className="d-flex flex-column flex-xl-column h-100 w-100">
                        <button className="h-100 w-100">basket content</button>
                    </div>
                </div>
                <div className="d-flex flex-column flex-xl-row h-100px w-100">
                    <button className='h-100 w-100'>contdinue</button>
                </div>
            </div>
        </>
    )
})
