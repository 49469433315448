import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TypeOfPayment } from './checkoutModel';
interface Props {
    paymentMethod?: TypeOfPayment
    setPaymentMethod(value: TypeOfPayment): void
    paymentAtPickupAvailable: boolean
    accountNumber: string;

}
export default observer(function DeliveryMethod({ paymentMethod, setPaymentMethod, paymentAtPickupAvailable, accountNumber }: Props) {

    const [model, setModel] = useState(paymentMethod);

    const handleModelChange = (value: TypeOfPayment) => {
        setPaymentMethod(value);
        setModel(value);
    }
    return (
        <>
            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 w-xl-50">
                <div className="d-flex flex-column gap-7 gap-lg-10 w-100">
                    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                        {/*begin::Card header*/}
                        <div className="card-header">
                            <div className="card-title">
                                <h2>Wybierz formę płatności</h2>
                            </div>
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-0">
                            <div className="row mt-0">
                                {/*begin::Input group*/}
                                <div className="mb-0 fv-row">
                                    {/*begin::Label*/}

                                    {/*end::Label*/}
                                    {/*begin::Options*/}
                                    <div className="mb-0">
                                        {/*begin:Option*/}
                                        <label className="d-flex flex-stack mb-5 cursor-pointer" onClick={() => handleModelChange(TypeOfPayment.Transfer)}>
                                            {/*begin:Label*/}
                                            <span className="d-flex align-items-center me-2">
                                                {/*begin::Icon*/}
                                                <span className="symbol symbol-75px me-6">
                                                    <span className="symbol-label">
                                                        {/*begin::Svg Icon | path: icons/duotune/finance/fin001.svg*/}
                                                        <span className="svg-icon svg-icon-3x svg-icon-primary">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="black" />
                                                                <path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="black" />
                                                            </svg>
                                                        </span>
                                                        {/*end::Svg Icon*/}
                                                    </span>
                                                </span>
                                                {/*end::Icon*/}
                                                {/*begin::Description*/}
                                                <span className="d-flex flex-column">
                                                    <span className="fw-bolder text-gray-800 text-hover-primary fs-5">Przelew tradycyjny</span>
                                                    <span className="fs-6 fw-bold text-muted">Przelew na konto: {accountNumber} Ing Bank Śląski<br />
                                                        (Wszystkie dane do przelewu zobaczysz<br /> w kolejnym kroku jeszcze raz)</span>
                                                </span>
                                                {/*end:Description*/}
                                            </span>
                                            {/*end:Label*/}
                                            {/*begin:Input*/}
                                            <span className="form-check form-check-custom form-check-solid">
                                                <input className="form-check-input" type="radio" checked={model === TypeOfPayment.Transfer} onChange={() => { }} />
                                            </span>
                                            {/*end:Input*/}
                                        </label>
                                        {/*end::Option*/}
                                        {/*begin:Option*/}
                                        <label className="d-flex flex-stack mb-5 cursor-pointer" onClick={() => handleModelChange(TypeOfPayment.Blik)}>
                                            {/*begin:Label*/}
                                            <span className="d-flex align-items-center me-2">
                                                {/*begin::Icon*/}
                                                <span className="symbol symbol-75px me-6">
                                                    <span className="symbol-label">
                                                        {/*begin::Svg Icon | path: icons/duotune/graphs/gra008.svg*/}
                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-600">
                                                            <img src="/customer-custom/Przelewy24_logo.svg" alt='przelewy 24' className='w-50px' />
                                                        </span>
                                                        {/*end::Svg Icon*/}
                                                    </span>
                                                </span>
                                                {/*end::Icon*/}
                                                {/*begin::Description*/}
                                                <span className="d-flex flex-column">
                                                    <span className="fw-bolder text-gray-800 fs-5 text-hover-primary">Szybkie przelewy przez Przelewy24</span>
                                                    <span className="fs-6 fw-bold text-muted">Szybki przelew gwarantujący najszybszą wysyłkę</span>
                                                </span>
                                                {/*end:Description*/}
                                            </span>
                                            {/*end:Label*/}
                                            {/*begin:Input*/}
                                            <span className="form-check form-check-custom form-check-solid">
                                                <input className="form-check-input" type="radio" checked={model === TypeOfPayment.Blik} onChange={() => { }} />
                                            </span>
                                            {/*end:Input*/}
                                        </label>
                                        {/*end::Option*/}
                                        {/*begin:Option*/}
                                        <label className={`d-flex flex-stack mb-0 cursor-pointer ${paymentAtPickupAvailable ? 'opacity-100' : 'opacity-75'}`} onClick={() => paymentAtPickupAvailable && handleModelChange(TypeOfPayment.AtPickup)}>
                                            {/*begin:Label*/}
                                            <span className="d-flex align-items-center me-2">
                                                {/*begin::Icon*/}
                                                <span className="symbol symbol-75px me-6">
                                                    <span className="symbol-label">
                                                        {/*begin::Svg Icon | path: icons/duotune/finance/fin001.svg*/}
                                                        <span className="svg-icon svg-icon-3x svg-icon-danger">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.3" d="M18 10V20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20V10H18Z" fill="black" />
                                                                <path opacity="0.3" d="M11 10V17H6V10H4V20C4 20.6 4.4 21 5 21H12C12.6 21 13 20.6 13 20V10H11Z" fill="black" />
                                                                <path opacity="0.3" d="M10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10H10Z" fill="black" />
                                                                <path opacity="0.3" d="M18 10C18 11.1 17.1 12 16 12C14.9 12 14 11.1 14 10H18Z" fill="black" />
                                                                <path opacity="0.3" d="M14 4H10V10H14V4Z" fill="black" />
                                                                <path opacity="0.3" d="M17 4H20L22 10H18L17 4Z" fill="black" />
                                                                <path opacity="0.3" d="M7 4H4L2 10H6L7 4Z" fill="black" />
                                                                <path d="M6 10C6 11.1 5.1 12 4 12C2.9 12 2 11.1 2 10H6ZM10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10H10ZM18 10C18 11.1 18.9 12 20 12C21.1 12 22 11.1 22 10H18ZM19 2H5C4.4 2 4 2.4 4 3V4H20V3C20 2.4 19.6 2 19 2ZM12 17C12 16.4 11.6 16 11 16H6C5.4 16 5 16.4 5 17C5 17.6 5.4 18 6 18H11C11.6 18 12 17.6 12 17Z" fill="black" />
                                                            </svg>
                                                        </span>
                                                        {/*end::Svg Icon*/}
                                                    </span>
                                                </span>
                                                {/*end::Icon*/}
                                                {/*begin::Description*/}
                                                <span className="d-flex flex-column">
                                                    <span className={`fw-bolder text-gray-800 fs-5 ${paymentAtPickupAvailable ? 'text-hover-primary' : ''}`}>Płatność przy odbiorze</span>
                                                    {paymentAtPickupAvailable &&
                                                        <span className="fs-6 fw-bold text-muted">Gdy wybierasz tą formę płatności pamiętaj, że paczkę musisz odebrać do jutra do 18:00</span>}
                                                    {!paymentAtPickupAvailable &&
                                                        <span className="fs-6 fw-bold text-muted">Ta forma jest niedostępna ponieważ wybrałaś kuriera lub InPost. Płatność przy odbiorze jest
                                                            dostępna tylko przy odbiorze osobistym.</span>}
                                                </span>
                                                {/*end:Description*/}
                                            </span>
                                            {/*end:Label*/}
                                            {/*begin:Input*/}
                                            <span className="form-check form-check-custom form-check-solid">
                                                <input className="form-check-input" type="radio" disabled={!paymentAtPickupAvailable} checked={model === TypeOfPayment.AtPickup} onChange={() => { }} />
                                            </span>
                                            {/*end:Input*/}
                                        </label>
                                        {/*end::Option*/}
                                    </div>
                                    {/*end::Options*/}
                                </div>
                                {/*end::Input group*/}
                            </div>
                        </div>
                        {/*end::Card body*/}
                    </div>
                </div>
            </div>
        </>
    )
})
