export enum TypeOfPayment {
    Transfer = "Transfer",
    Blik = "Blik",
    AtPickup = "AtPickup"
}

export enum TypeOfDelivery {
    InPost = "InPost",
    DPD = "DPD",
    Pickup = "Pickup"
}

export class CheckoutModel {
    orderNumber: string = '#76834'
    customer: Customer = new Customer();
    deliveryAddress: Address = new Address();
    openParcel: boolean = false;
    typeOfDelivery?: TypeOfDelivery;
    typeOfPayment?: TypeOfPayment;
    paczkomatNumber?: string;
    paczkomatAddress?: string;
    remarks: string = "";
    rememberData: boolean = true;
    transferScreenshotName: string = '';

    deliveryCost: DeliveryCost = new DeliveryCost();
    shopData: ShopData = new ShopData();
    products: Product[] = initialProducts;

    getSumToPay() {
        let productsToSum = this.products.filter((a) => !a.isDeleted);
        if (productsToSum && productsToSum.length > 0) return productsToSum.map(a => a.price).reduce((a, b) => a + b) + this.getDeliveryCost();
        return 0;
    }

    getDeliveryCost(){
        switch(this.typeOfDelivery){
            case TypeOfDelivery.InPost: return this.deliveryCost.InPost;
            case TypeOfDelivery.DPD: return this.deliveryCost.DPD;
        }
        return 0;
    }
}

export class Customer {
    firstName: string = "Adrianna";
    lastName: string = "Nowakowska";
    email: string = "adrianna.nowakowska@ example.com";
    phone: string = "+48 123 456 789";

    isValid(): boolean {
        return this.firstName.trim()!=='' && this.lastName.trim()!=='' && this.email.trim()!=='' && this.phone.trim()!=='';
    }
}

export class Address {
    city: string = "Kraków";
    streetAndNumber: string = "ul. Słowackiego 66/12";
    postCode: string = "30-016";

    isValid(): boolean {
        return this.city.trim()!=='' && this.streetAndNumber.trim()!=='' && this.postCode.trim()!=='';
    }
}



export class DeliveryCost {
    InPost: number = 10;
    DPD: number = 12;
}

export class ShopData {
    name: string = 'Najlepszy Butik Online';
    addressLine1: string = 'ul. Cicha 17';
    addressLine2: string = '00-015 Warszawa';
    pickupTime: string = 'od poniedziałku do piątku, 10:00 - 16:00';
    accountNumber: string = '7694 0958 4094 0493';
}

export class Product {
    id: string = '';
    img: string = '';
    name: string = '';
    description: string = '';
    price: number;
    isDeleted: boolean = false

    constructor(id: string, img: string, name: string, description: string, price: number) {
        this.id = id;
        this.img = img;
        this.name = name;
        this.description = description;
        this.price = price;
    }
}

export const initialProducts = [
    new Product('5c4755df-6f1e-43c9-9bb1-dfa873008f35', '224.gif', 'Kapelusz Fedora x6786', 'Przewiewny kapelusz fedora', 89),
    new Product('81656c1e-8dce-461e-bd17-bba56806b725', '220.gif', 'Sukienka w maki c045', 'Letnia sukienka, bawełna 100%', 129),
    new Product('d68c2d23-be4a-47e1-bc2e-348a0745eeb8', '48.gif', 'Szpilki czerwone c0881', 'Szpilki na obcasie 6cm, czerwone, skóra ekologiczna', 84),
    //new Product('65f8cbbf-6946-4161-a077-fb5da623d978', '221.gif', 'Sukienka kwiaty 065b', 'Przewiewna sukienka do kolana', 89),
    //new Product('65f8cbbf-6946-4161-a077-fb5da623d978', '223.gif', 'Ramoneska 065b', 'Zawsze modna kurteczka skóra ekologiczna', 89),
    //new Product('daa5c125-6792-4146-837b-5a61f919826c', '222.gif', 'Torebka czerwona 164c', 'Torebka elegancka mała, skóra ekologiczna', 57),
];