import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

interface Props {
    setFiles: (files: any) => void
}


export default function DropZoneWidget({ setFiles }: Props) {
    const dzStyles = {
        border: 'dashed 3px #eee',
        borderColor: '#eee',
        borderRadius: '5px',
        paddingTop: '30px',
        textAlign: 'center' as 'center',
        height: 200,
        backgroundColor: '#E6CF03'
    }

    const dzActive = {
        borderColor: 'black',
    }

    const onDrop = useCallback(acceptedFiles => {
        setFiles(acceptedFiles.map((file: any) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })))
    }, [setFiles])
    
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <div {...getRootProps()} style={isDragActive ? { ...dzStyles, ...dzActive } : { ...dzStyles }} >
            <input {...getInputProps()} />
            <>
                <h3>Kliknij tu i dodaj lub przeciągnij screenshot z potwierdzeniem przelewu. Możesz też wysłać go nam w wiadomości prywatnej później.</h3>
                <img src="/assets/media/illustrations/sigma-1/16.png" alt="" className="mw-100 h-175px" />
            </>
            
        </div>
    )
}