import React from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink, useParams } from 'react-router-dom';
import urls from '../../app/common/urls';

export default observer(function Redirect() {
    const { target } = useParams<{ target: string }>();
    return (
        <>
            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 w-xl-50">
                <div className="d-flex flex-column gap-7 gap-lg-10 w-100">
                    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                        {/*begin::Card header*/}
                        {target === 'channel' &&
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>W tym miejscu jest przekierowanie do Twojego Kanału</h2>
                                </div>
                                <div className="text-muted fw-bold fs-6">
                                    Po kliknięciu tego przycisku klient zostaje przekierowany do Kanału
                                    na którym dokonywał zakupu. Jako że oglądasz teraz demo widzisz ten napis.
                                </div>
                            </div>}
                        {target === 'terms' &&
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>W tym miejscu pokażemy regulamin Twojego sklepu</h2>
                                </div>
                                <div className="text-muted fw-bold fs-6">
                                    Po kliknięciu tego przycisku klient zostaje przekierowany do Regulaminu Twojego sklepu. Jako że oglądasz teraz demo widzisz ten napis.
                                </div>
                            </div>}
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-0">
                            <div className="row mt-10">
                                {/*begin::Input group*/}
                                <div className="fv-row d-flex justify-content-center" style={{ marginBottom: '-30px' }}>
                                    {target === 'channel' &&
                                        <img src="/assets/media/illustrations/sigma-1/9.png" alt="" className="mw-100 h-200px h-sm-325px" />}
                                    {target === 'terms' &&
                                        <img src="/assets/media/illustrations/sigma-1/17.png" alt="" className="mw-100 h-200px h-sm-325px" />}
                                </div>
                                {/*end::Input group*/}
                            </div>
                        </div>
                        {/*end::Card body*/}
                    </div>
                    <NavLink to={urls.basket()} className="btn btn-lg btn-info" >Wróć do demo koszyka
                        <span className="svg-icon svg-icon-4 ms-1 me-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                                <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                            </svg>
                        </span>
                    </NavLink>
                </div>
            </div>
        </>
    )
})
