import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
interface Props {
    openParcel: boolean
    setOpenParcel(openParcel: boolean): void
}
export default observer(function OpenParcel({ openParcel, setOpenParcel }: Props) {
    const [_openParcel, _setOpenParcel] = useState(openParcel);

    const handleModelChange = (value: boolean) => {
        _setOpenParcel(value);
        setOpenParcel(value);
    }

    return (
        <>
            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 w-xl-50">
                <div className="d-flex flex-column gap-7 gap-lg-10 w-100">
                    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                        {/*begin::Card header*/}
                        <div className="card-header">
                            <div className="card-title">
                                <h2>Czy chcesz utworzyć Otwartą Paczkę?</h2>
                            </div>
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-0">
                            <div className="row mt-0">
                                {/*begin::Col*/}
                                <div className="col-lg-6">
                                    {/*begin::Option*/}
                                    <input type="radio" className="btn-check" checked={!_openParcel} onChange={() => { }} />
                                    <label className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" onClick={() => handleModelChange(false)}>
                                        {/*begin::Svg Icon | path: icons/duotune/communication/com005.svg*/}
                                        <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z" fill="black" />
                                                <path d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z" fill="black" />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                        {/*begin::Info*/}
                                        <span className="d-block fw-bold text-start">
                                            <span className="text-dark fw-bolder d-block fs-4 mb-2">Nie, wyślij paczkę od razu po transmisji</span>
                                            <span className="text-muted fw-bold fs-6">Nie mogę się doczekać tych przedmiotów, wyślijcie je jak najszybciej.</span>
                                        </span>
                                        {/*end::Info*/}
                                    </label>
                                    {/*end::Option*/}
                                </div>
                                {/*end::Col*/}
                                {/*begin::Col*/}
                                <div className="col-lg-6">
                                    {/*begin::Option*/}
                                    <input type="radio" className="btn-check" checked={_openParcel} onChange={() => { }} />
                                    <label className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center" onClick={() => handleModelChange(true)}>
                                        {/*begin::Svg Icon | path: icons/duotune/finance/fin006.svg*/}
                                        <span className="svg-icon svg-icon-3x me-5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M20 22H4C3.4 22 3 21.6 3 21V2H21V21C21 21.6 20.6 22 20 22Z" fill="black" />
                                                <path d="M12 14C9.2 14 7 11.8 7 9V5C7 4.4 7.4 4 8 4C8.6 4 9 4.4 9 5V9C9 10.7 10.3 12 12 12C13.7 12 15 10.7 15 9V5C15 4.4 15.4 4 16 4C16.6 4 17 4.4 17 5V9C17 11.8 14.8 14 12 14Z" fill="black" />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                        {/*begin::Info*/}
                                        <span className="d-block fw-bold text-start">
                                            <span className="text-dark fw-bolder d-block fs-4 mb-2">Tak, chcę utworzyć Otwartą Paczkę</span>
                                            <span className="text-muted fw-bold fs-6">Chętnie zobaczę kolejne transmisje i być może coś niej dołożę. Za przesyłkę płacę tylko raz</span>
                                        </span>
                                        {/*end::Info*/}
                                    </label>
                                    {/*end::Option*/}
                                </div>
                                {/*end::Col*/}
                            </div>
                            <div className='row mt-0'>
                                <div className="text-muted fw-bold fs-6">Otwarta Paczka polega na tym że dziś kupujesz rzeczy które masz w koszyku ale nie wysyłamy Ci paczki natychmiast. Dzięki temu
                                    Ty możesz oglądać nasze kolejne traksmisje i dokładać kolejne przedmioty do Otwartej Paczki. <br />Gdy już stwierdzisz, że chcesz abyśmy paczkę wysłali klikasz
                                    Wyślij paczkę na tym portalu lub piszesz do nasz na FB w prywatnej wiadomości "poproszę paczkę".<span className='text-gray-800'> Dzięki temu za przesyłkę płacisz tylko raz! :)</span>
                                </div>
                            </div>
                        </div>
                        {/*end::Card body*/}
                    </div>
                </div>
            </div>
        </>
    )
})
