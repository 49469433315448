import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Customer, Address } from './checkoutModel';

interface Props {
    simplified: boolean;
    customer: Customer;
    address: Address;
    remarks: string;
    rememberData: boolean;
    setCustomer(customer: Customer): void
    setAddress(address: Address): void
    setRemarks(remarks: string): void
    setRememberData(rememberData: boolean): void


}
export default observer(function CustomerData({ simplified, customer, address, setCustomer, setAddress, remarks, setRemarks, rememberData, setRememberData }: Props) {

    const [cmodel, csetModel] = useState(customer);
    const [amodel, asetModel] = useState(address);
    const [remarksModel, setRemarksModel] = useState(remarks);
    const [rememberDataModel, setRememberDataModel] = useState(rememberData);

    const handleCModelChange = (value: Customer) => {
        setCustomer(value);
        csetModel(value);
    }

    const handleAModelChange = (value: Address) => {
        setAddress(value);
        asetModel(value);
    }

    return (
        <>
            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 w-xl-50">
                <div className="d-flex flex-column gap-7 gap-lg-10 w-100">
                    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                        {/*begin::Card header*/}
                        <div className="card-header">
                            <div className="card-title">
                                <h2 className="fw-bolder text-dark">Twoje dane</h2>
                            </div>
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-0">

                            {/*begin::Input group*/}
                            <div className="row fv-row">
                                <div className="col-xl-6 mb-10">
                                    {/*begin::Label*/}
                                    <label className="d-flex align-items-center form-label">
                                        <span className="required">Imię</span>
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Input*/}
                                    <input defaultValue={cmodel.firstName} className="form-control form-control-lg form-control-solid" onChange={(e) => { cmodel.firstName = e.target.value; handleCModelChange(cmodel) }} />
                                    {/*end::Input*/}
                                </div>
                                <div className="col-xl-6 mb-10">
                                    {/*begin::Label*/}
                                    <label className="d-flex align-items-center form-label">
                                        <span className="required">Nazwisko</span>
                                    </label>
                                    {/*end::Label*/}
                                    {/*begin::Input*/}
                                    <input defaultValue={cmodel.lastName} className="form-control form-control-lg form-control-solid" onChange={(e) => { cmodel.lastName = e.target.value; handleCModelChange(cmodel) }} />
                                    {/*end::Input*/}
                                </div>
                            </div>
                            {/*end::Input group*/}
                            {/*begin::Input group*/}
                            <div className="row fv-row">
                                <div className="col-xl-6 mb-10">
                                    {/*begin::Label*/}
                                    <label className="fs-6 fw-bold form-label required">Email kontaktowy</label>
                                    {/*end::Label*/}
                                    {/*begin::Input*/}
                                    <input defaultValue={cmodel.email} className="form-control form-control-lg form-control-solid" onChange={(e) => { cmodel.email = e.target.value; handleCModelChange(cmodel) }} />
                                    {/*end::Input*/}
                                </div>
                                <div className="col-xl-6 mb-10">
                                    {/*begin::Label*/}
                                    <label className="fs-6 fw-bold form-label required">Telefon kontaktowy</label>
                                    {/*end::Label*/}
                                    {/*begin::Input*/}
                                    <input defaultValue={cmodel.phone} className="form-control form-control-lg form-control-solid" onChange={(e) => { cmodel.phone = e.target.value; handleCModelChange(cmodel) }} />
                                    {/*end::Input*/}
                                </div>
                            </div>
                            {/*begin::Input group*/}
                            {!simplified &&
                                <>
                                    <div className="row fv-row">
                                        <div className="col-xl-12 mb-10">
                                            {/*begin::Label*/}
                                            <label className="d-flex align-items-center form-label">
                                                <span className="required">Ulica i numer</span>
                                            </label>
                                            {/*end::Label*/}
                                            {/*begin::Input*/}
                                            <input defaultValue={amodel.streetAndNumber} className="form-control form-control-lg form-control-solid" onChange={(e) => { amodel.streetAndNumber = e.target.value; handleAModelChange(amodel) }} />
                                            {/*end::Input*/}
                                        </div>
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="row fv-row">
                                        <div className="col-xl-6 mb-10">
                                            {/*begin::Label*/}
                                            <label className="d-flex align-items-center form-label">
                                                <span className="required">Kod pocztowy</span>
                                            </label>
                                            {/*end::Label*/}
                                            {/*begin::Input*/}
                                            <input defaultValue={amodel.postCode} className="form-control form-control-lg form-control-solid" onChange={(e) => { amodel.postCode = e.target.value; handleAModelChange(amodel) }} />
                                            {/*end::Input*/}
                                        </div>
                                        <div className="col-xl-6 mb-10">
                                            {/*begin::Label*/}
                                            <label className="d-flex align-items-center form-label">
                                                <span className="required">Miasto</span>
                                            </label>
                                            {/*end::Label*/}
                                            {/*begin::Input*/}
                                            <input defaultValue={amodel.city} className="form-control form-control-lg form-control-solid" onChange={(e) => { amodel.city = e.target.value; handleAModelChange(amodel) }} />
                                            {/*end::Input*/}
                                        </div>
                                    </div>
                                    {/*end::Input group*/}</>}
                            {/*begin::Input group*/}
                            <div className="fv-row mb-10">
                                {/*end::Label*/}
                                <label className="form-label">Uwagi dodatkowe</label>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <textarea defaultValue={remarksModel} className="form-control form-control-lg form-control-solid" rows={3} onChange={(e) => { setRemarks(e.target.value); setRemarksModel(e.target.value) }}></textarea>
                                {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}                            
                            <div className="d-flex flex-stack">
                                {/*begin::Label*/}
                                <div className="me-5">
                                    <label className="fs-6 fw-bold form-label">Zapamiętać te dane dla przyszłych zakupów?</label>
                                    <div className="fs-7 fw-bold text-muted">Dzięki temu następnym razem kupując u nas nie będziesz musiała wszystkiego jeszcze raz wypełniać.
                                        Nawet jeśli zapamiętamy te dane, będziesz mogła je zmienić - wieć bez obaw :)</div>
                                </div>
                                {/*end::Label*/}
                                {/*begin::Switch*/}
                                <label className="form-check form-switch form-check-custom form-check-solid">
                                    <input className="form-check-input" checked={rememberDataModel} onChange={(e) => { setRememberData(e.target.checked); setRememberDataModel(e.target.checked) }} type="checkbox" defaultValue="1" />
                                    <span className="form-check-label fw-bold text-muted">Zapamiętaj</span>
                                </label>
                                {/*end::Switch*/}
                            </div>
                            <div className="d-flex flex-center" style={{marginBottom: '-70px'}}>
                                <img src="/assets/media/illustrations/sigma-1/8.png" alt="" className="mw-100 h-200px" />
                            </div>
                        </div>
                        {/*end::Card body*/}
                    </div>
                </div>
            </div>
        </>
    )
})
