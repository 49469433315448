import React from 'react';
import { observer } from 'mobx-react-lite';
import { CheckoutModel, Product, TypeOfDelivery, TypeOfPayment } from './checkoutModel';

interface Props {
    model: CheckoutModel
    setProducts(products: Product[]): void
}

export default observer(function FinishSummary({ model, setProducts: setProductsParent }: Props) {
    const { products } = model;

    return (
        <>
            {/*begin::Orders*/}
            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 w-xl-75">
                {/*begin::Product List*/}
                <div className="d-flex flex-column gap-7 gap-lg-10 w-100">
                    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                        {/*begin::Card header*/}
                        <div className="card-header">
                            <div className="card-title">
                                <h2>Hurra! Kupione!</h2>
                            </div>
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-0">
                            <div className="table-responsive">
                                {/*begin::Table*/}
                                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                    {/*begin::Table head*/}
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                            <th className="min-w-175px">Produkt</th>
                                            <th className="w-75px text-end">Cena</th>
                                        </tr>
                                    </thead>
                                    {/*end::Table head*/}
                                    {/*begin::Table body*/}
                                    <tbody className="fw-bold text-gray-600">
                                        {products.length > 0 && products.map((p) => (
                                            <tr key={p.id}>
                                                {/*begin::Product*/}
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        {/*begin::Thumbnail*/}
                                                        <span className="symbol symbol-50px">
                                                            <span className={`symbol-label ${p.isDeleted ? 'opacity-50' : 'opacity-100'}`} style={{ backgroundImage: `url(/assets/media//stock/ecommerce/${p.img})` }} ></span>
                                                        </span>
                                                        {/*end::Thumbnail*/}
                                                        {/*begin::Title*/}
                                                        <div className="ms-5">
                                                            <span className={`fw-bolder ${p.isDeleted ? 'text-gray-500 text-decoration-line-through' : 'text-gray-800'}`} >{p.name}</span>
                                                            <div className={`fs-7 ${p.isDeleted ? 'text-gray-400 text-decoration-line-through' : 'text-gray-600'}`} >{p.description}</div>
                                                        </div>
                                                        {/*end::Title*/}
                                                    </div>
                                                </td>
                                                {/*end::Product*/}
                                                {/*begin::Total*/}
                                                <td className={`text-end ${p.isDeleted ? 'text-decoration-line-through' : 'text-black'}`}>{!p.isDeleted ? p.price : 0} zł</td>
                                                {/*end::Total*/}
                                            </tr>
                                        ))}
                                        {/*begin::Shipping*/}
                                        <tr>
                                            <td colSpan={1} className="text-end">Koszty wysyłki</td>
                                            <td className="text-end">{model.getDeliveryCost()} zł</td>
                                        </tr>
                                        {/*end::Shipping*/}
                                        {/*begin::Grand total*/}
                                        <tr>
                                            <td colSpan={1} className="text-end">Suma</td>
                                            <td className="text-dark fs-3 fw-boldest text-end">{model.getSumToPay()} zł</td>
                                        </tr>
                                        {/*end::Grand total*/}
                                    </tbody>
                                    {/*end::Table head*/}
                                </table>
                                {/*end::Table*/}
                            </div>
                            {model.remarks &&
                                <div>
                                    <h4>Twoje Uwagi dodatkowe:</h4>
                                    <div>{model.remarks}</div>
                                </div>}
                        </div>
                        {/*end::Card body*/}
                        <div className="d-flex flex-center" style={{ marginBottom: '-10px' }}>
                            <img src="/assets/media/illustrations/sigma-1/12.png" alt="" className="mw-100 h-175px h-xl-300px" />
                        </div>
                    </div>
                </div>
                {/*end::Product List*/}
                <div className="d-flex flex-column gap-7 gap-lg-10">
                    {/*begin::Payment address*/}
                    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                        {/*begin::Card header*/}
                        <div className="card-header">
                            <div className="card-title">
                                <h2>Twoje dane</h2>
                            </div>
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-0">
                            <div className="table-responsive">
                                {/*begin::Table*/}
                                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                    {/*begin::Table body*/}
                                    <tbody className="fw-bold text-gray-600">
                                        {/*begin::Customer name*/}
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
                                                    <span className="svg-icon svg-icon-2 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black" />
                                                            <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/*end::Svg Icon*/}Imię/nazwisko</div>
                                            </td>
                                            <td className="fw-bolder text-end">
                                                <div className="d-flex align-items-center justify-content-end">
                                                    {/*begin::Name*/}
                                                    <span className="text-gray-600">{`${model.customer.firstName} ${model.customer.lastName}`}</span>
                                                    {/*end::Name*/}
                                                </div>
                                            </td>
                                        </tr>
                                        {/*end::Customer name*/}
                                        {/*begin::Customer email*/}
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    {/*begin::Svg Icon | path: icons/duotune/communication/com011.svg*/}
                                                    <span className="svg-icon svg-icon-2 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="black" />
                                                            <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/*end::Svg Icon*/}Email</div>
                                            </td>
                                            <td className="fw-bolder text-end">
                                                <span className="text-gray-600">{model.customer.email}</span>
                                            </td>
                                        </tr>
                                        {/*end::Payment method*/}
                                        {/*begin::Date*/}
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    {/*begin::Svg Icon | path: icons/duotune/electronics/elc003.svg*/}
                                                    <span className="svg-icon svg-icon-2 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z" fill="black" />
                                                            <path opacity="0.3" d="M19 4H5V20H19V4Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/*end::Svg Icon*/}Telefon</div>
                                            </td>
                                            <td className="fw-bolder text-end">{model.customer.phone}</td>
                                        </tr>
                                        {/*end::Date*/}
                                    </tbody>
                                    {/*end::Table body*/}
                                </table>
                                {/*end::Table*/}
                            </div>
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Payment address*/}
                    {/*begin::Payment address*/}
                    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                        {/*begin::Card header*/}
                        <div className="card-header">
                            <div className="card-title">
                                <h2>Detale zamówienia</h2>
                            </div>
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-0">
                            <div className="table-responsive">
                                {/*begin::Table*/}
                                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                    {/*begin::Table body*/}
                                    <tbody className="fw-bold text-gray-600">
                                        {/*begin::Customer name*/}
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
                                                    <span className="svg-icon svg-icon-2 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M22 7H2V11H22V7Z" fill="black" />
                                                            <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/*end::Svg Icon*/}Płatność</div>
                                            </td>
                                            <td className="fw-bolder text-end">
                                                {model.typeOfPayment === TypeOfPayment.Blik &&
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <span className="text-success">Szybkie przelewy<br />(Zaksięgowane)</span>
                                                    </div>}
                                                {model.typeOfPayment === TypeOfPayment.Transfer &&
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <span className="text-warning">Przelew klasyczny<br />(Czekamy na przelew)</span>
                                                    </div>}
                                                {model.typeOfPayment === TypeOfPayment.AtPickup &&
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <span className="text-success">Płatność przy odbiorze</span>
                                                    </div>}
                                            </td>
                                        </tr>
                                        {/*end::Customer name*/}
                                        {/*begin::Customer email*/}
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    {/*begin::Svg Icon | path: icons/duotune/communication/com011.svg*/}
                                                    <span className="svg-icon svg-icon-2 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z" fill="black" />
                                                            <path opacity="0.3" d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/*end::Svg Icon*/}Wysyłka</div>
                                            </td>
                                            {model.typeOfDelivery === TypeOfDelivery.Pickup &&
                                                <td className="fw-bolder text-end">
                                                    <span className="text-gray-600">Odbiór osobisty<br />(odbierz w ciągu 3 dni)</span>
                                                </td>}
                                            {model.typeOfDelivery === TypeOfDelivery.DPD &&
                                                <td className="fw-bolder text-end">
                                                    <span className="text-gray-600">Kurier DPD<br />(z reguły w 3 dni u Ciebie)</span>
                                                </td>}
                                            {model.typeOfDelivery === TypeOfDelivery.InPost &&
                                                <td className="fw-bolder text-end">
                                                    <span className="text-gray-600">Paczkomaty InPost<br />(za ok 4 dni w&nbsp;Paczkomacie)</span>
                                                </td>}
                                        </tr>
                                        {/*end::Payment method*/}
                                        {/*begin::Date*/}
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    {/*begin::Svg Icon | path: icons/duotune/electronics/elc003.svg*/}
                                                    <span className="svg-icon svg-icon-2 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M20 22H4C3.4 22 3 21.6 3 21V2H21V21C21 21.6 20.6 22 20 22Z" fill="black" />
                                                            <path d="M12 14C9.2 14 7 11.8 7 9V5C7 4.4 7.4 4 8 4C8.6 4 9 4.4 9 5V9C9 10.7 10.3 12 12 12C13.7 12 15 10.7 15 9V5C15 4.4 15.4 4 16 4C16.6 4 17 4.4 17 5V9C17 11.8 14.8 14 12 14Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/*end::Svg Icon*/}Forma</div>
                                            </td>
                                            {model.openParcel &&
                                                <td className="fw-bolder text-end text-success">Otwarta&nbsp;paczka</td>}
                                            {!model.openParcel &&
                                                <td className="fw-bolder text-end text-success">Natychmiastowa&nbsp;paczka</td>}
                                        </tr>
                                        {/*end::Date*/}
                                        {/*begin::Date*/}
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    {/*begin::Svg Icon | path: icons/duotune/electronics/elc003.svg*/}
                                                    <span className="svg-icon svg-icon-2 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z" fill="black" />
                                                            <path d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z" fill="black" />
                                                            <path d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/*end::Svg Icon*/}Numer<br />Zamówienia</div>
                                            </td>
                                            <td className="fw-bolder text-end text-gray-600">{model.orderNumber}</td>
                                        </tr>
                                        {/*end::Date*/}
                                    </tbody>
                                    {/*end::Table body*/}
                                </table>
                                {/*end::Table*/}
                            </div>
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Payment address*/}
                    {model.typeOfDelivery !== TypeOfDelivery.Pickup &&
                        <>
                            {/*begin::Shipping address*/}
                            <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                                {/*begin::Background*/}
                                <div className="position-absolute top-0 end-0 opacity-10 pe-none text-end">
                                    <img src="/assets/media/icons/duotune/ecommerce/ecm006.svg" className="w-175px" alt="basket" />
                                </div>
                                {/*end::Background*/}
                                {/*begin::Card header*/}
                                <div className="card-header">
                                    <div className="card-title">
                                        <h2>Wysyłka</h2>
                                    </div>
                                </div>
                                {/*end::Card header*/}
                                {/*begin::Card body*/}
                                {model.typeOfDelivery === TypeOfDelivery.DPD &&
                                    <div className="card-body pt-0">Kurier DPD na adres:,
                                        <br /> {model.customer.firstName} {model.customer.lastName},
                                        <br /> {model.deliveryAddress.streetAndNumber},
                                        <br /> {model.deliveryAddress.postCode} {model.deliveryAddress.city}
                                    </div>}
                                {model.typeOfDelivery === TypeOfDelivery.InPost &&
                                    <div className="card-body pt-0">Paczkomat InPost:,
                                        <br /> {model.customer.firstName} {model.customer.lastName},
                                        <br /> {model.paczkomatNumber},
                                        <br /> {model.paczkomatAddress}
                                    </div>}
                                {/*end::Card body*/}
                            </div>
                            {/*end::Shipping address*/}
                        </>}
                </div>
            </div>
            {/*end::Orders*/}
        </>
    )
})