import React from 'react';
import { observer } from 'mobx-react-lite';

export default observer(function Header() {
    return (
        <>
            {/*begin::Header*/}
            <div id="kt_header" className="header align-items-stretch">
                {/*begin::Container*/}
                <div className="header-container container d-flex flex-center align-items-center">
                    {/*begin::Header Logo*/}
                    <div className="header-logo mt-10 mt-md-0 flex-grow-0">
                        <img alt="Logo" src="/customer-custom/logo.svg" className="d-block h-45px h-md-60px" />
                    </div>
                    {/*end::Header Logo*/}
                </div>
                {/*end::Container*/}
            </div>
            {/*end::Header*/}
        </>
    )
})