import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import DropZoneWidget from '../../app/elements/DropZoneWidget';
import { CheckoutModel } from './checkoutModel';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface Props {
    checkoutModel: CheckoutModel,
    setScreenshotName(screenShotName: string): void
}


export default observer(function TransferAndScreenshot({ checkoutModel, setScreenshotName }: Props) {
    const [fileName, setFileName] = useState(checkoutModel.transferScreenshotName);

    const handleFileAdded = (files: any[]) => {
        setFileName(files[0].name);
        setScreenshotName(files[0].name)
    }

    const [option, setOption] = useState([false, false, false]);

    const handleOnCopy = (text: string, optionNumber: number) => {
        let arr=[false, false, false];
        arr[optionNumber] = true;
        setOption(arr);
        console.log(text)
    }

    function Skopiowano(props:any) {
        return <>
            {option[props.optionNumber] &&
                <span className='text-success'>(Skopiowano)</span>}

            {!option[props.optionNumber] &&
                <span>(Kliknij, aby skopiować)</span>}

        </>
    }

    return (
        <>
            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 w-xl-50">
                <div className="d-flex flex-column gap-7 gap-lg-10 w-100">
                    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                        {/*begin::Card header*/}
                        <div className="card-header">
                            <div className="card-title">
                                <h2>Zapłać za swoje zakupy - Przelew</h2>
                            </div>
                            <div className="text-muted fw-bold fs-6">Wybrałaś przelew tradycyjny. Jeśli chcesz skopiować jakąś daną - kliknij ją (nie dotyczy numeru bankowego)
                            </div>
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-0">
                            <div className="row mt-10">
                                {/*begin::Table*/}
                                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                    {/*begin::Table body*/}
                                    <tbody className="fw-bold text-gray-600">
                                        {/*begin::Customer name*/}
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    {/*begin::Svg Icon | path: icons/duotune/communication/com006.svg*/}
                                                    <span className="svg-icon svg-icon-2 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="black" />
                                                            <path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/*end::Svg Icon*/}Numer konta</div>
                                            </td>
                                            <td className="fw-bolder text-end">
                                                <div className="d-flex align-items-center justify-content-end">
                                                    {/*begin:: Avatar */}
                                                    <div className="symbol symbol-circle symbol-25px overflow-hidden me-3">
                                                        <div className="symbol-label">
                                                            <img src="/customer-custom/ing_icon.svg" alt="avatar" className="w-100" />
                                                        </div>
                                                    </div>
                                                    {/*end::Avatar*/}
                                                    {/*begin::Name*/}
                                                    <span className="text-gray-600">{checkoutModel.shopData.accountNumber}</span>
                                                    {/*end::Name*/}
                                                </div>
                                            </td>
                                        </tr>
                                        {/*end::Customer name*/}
                                        {/*begin::Customer email*/}
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    {/*begin::Svg Icon | path: icons/duotune/communication/com011.svg*/}
                                                    <span className="svg-icon svg-icon-2 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M18 10V20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20V10H18Z" fill="black" />
                                                            <path opacity="0.3" d="M11 10V17H6V10H4V20C4 20.6 4.4 21 5 21H12C12.6 21 13 20.6 13 20V10H11Z" fill="black" />
                                                            <path opacity="0.3" d="M10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10H10Z" fill="black" />
                                                            <path opacity="0.3" d="M18 10C18 11.1 17.1 12 16 12C14.9 12 14 11.1 14 10H18Z" fill="black" />
                                                            <path opacity="0.3" d="M14 4H10V10H14V4Z" fill="black" />
                                                            <path opacity="0.3" d="M17 4H20L22 10H18L17 4Z" fill="black" />
                                                            <path opacity="0.3" d="M7 4H4L2 10H6L7 4Z" fill="black" />
                                                            <path d="M6 10C6 11.1 5.1 12 4 12C2.9 12 2 11.1 2 10H6ZM10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10H10ZM18 10C18 11.1 18.9 12 20 12C21.1 12 22 11.1 22 10H18ZM19 2H5C4.4 2 4 2.4 4 3V4H20V3C20 2.4 19.6 2 19 2ZM12 17C12 16.4 11.6 16 11 16H6C5.4 16 5 16.4 5 17C5 17.6 5.4 18 6 18H11C11.6 18 12 17.6 12 17Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/*end::Svg Icon*/}Nazwa odbiorcy</div>
                                            </td>
                                            <td className="fw-bolder text-end">
                                                <CopyToClipboard text={`${checkoutModel.shopData.name} ${checkoutModel.shopData.addressLine1} ${checkoutModel.shopData.addressLine2}`} onCopy={(t) => handleOnCopy(t, 0)}>
                                                    <span className="text-gray-600 text-hover-primary">{checkoutModel.shopData.name}, <br /> {checkoutModel.shopData.addressLine1} {checkoutModel.shopData.addressLine2}
                                                    <br /><Skopiowano optionNumber={0} />
                                                    </span>
                                                </CopyToClipboard>
                                            </td>
                                        </tr>
                                        {/*end::Payment method*/}
                                        {/*begin::Date*/}
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    {/*begin::Svg Icon | path: icons/duotune/electronics/elc003.svg*/}
                                                    <span className="svg-icon svg-icon-2 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                                            <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
                                                            <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/*end::Svg Icon*/}Tytuł</div>
                                            </td>
                                            <td className="fw-bolder text-end text-hover-primary">
                                                <CopyToClipboard text={`${checkoutModel.orderNumber} ${checkoutModel.customer.firstName}`} onCopy={(t) => handleOnCopy(t, 1)}>
                                                    <span>{checkoutModel.orderNumber} {checkoutModel.customer.firstName} {checkoutModel.customer.lastName}
                                                    <br /><Skopiowano optionNumber={1} /></span>
                                                </CopyToClipboard>
                                            </td>
                                        </tr>
                                        {/*end::Date*/}
                                        {/*begin::Date*/}
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    {/*begin::Svg Icon | path: icons/duotune/electronics/elc003.svg*/}
                                                    <span className="svg-icon svg-icon-2 me-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z" fill="black" />
                                                            <path d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z" fill="black" />
                                                            <path d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                    {/*end::Svg Icon*/}Należność</div>
                                            </td>
                                            <td className="fw-bolder text-end text-hover-primary">
                                                <CopyToClipboard text={`${checkoutModel.getSumToPay()}`} onCopy={(t) => handleOnCopy(t, 2)}>
                                                    <span>{checkoutModel.getSumToPay()} zł
                                                    <br /><Skopiowano optionNumber={2} />
                                                    </span>
                                                </CopyToClipboard>
                                            </td>
                                        </tr>
                                        {fileName?.length > 0 &&
                                            <tr>
                                                <td className="text-muted">
                                                    <div className="d-flex align-items-center">
                                                        {/*begin::Svg Icon | path: icons/duotune/electronics/elc003.svg*/}
                                                        <span className="svg-icon svg-icon-2 me-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM11.7 17.7L16 14C16.4 13.6 16.4 12.9 16 12.5C15.6 12.1 15.4 12.6 15 13L11 16L9 15C8.6 14.6 8.4 14.1 8 14.5C7.6 14.9 8.1 15.6 8.5 16L10.3 17.7C10.5 17.9 10.8 18 11 18C11.2 18 11.5 17.9 11.7 17.7Z" fill="black" />
                                                                <path d="M10.4343 15.4343L9.25 14.25C8.83579 13.8358 8.16421 13.8358 7.75 14.25C7.33579 14.6642 7.33579 15.3358 7.75 15.75L10.2929 18.2929C10.6834 18.6834 11.3166 18.6834 11.7071 18.2929L16.25 13.75C16.6642 13.3358 16.6642 12.6642 16.25 12.25C15.8358 11.8358 15.1642 11.8358 14.75 12.25L11.5657 15.4343C11.2533 15.7467 10.7467 15.7467 10.4343 15.4343Z" fill="black" />
                                                                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
                                                            </svg>
                                                        </span>
                                                        {/*end::Svg Icon*/}Dodany plik</div>
                                                </td>
                                                <td className="fw-bolder text-end">{fileName}</td>
                                            </tr>}
                                        {/*end::Date*/}
                                    </tbody>
                                    {/*end::Table body*/}
                                </table>
                                {/*end::Table*/}
                            </div>
                            {!fileName &&
                                <div className="row mt-10">
                                    <DropZoneWidget setFiles={handleFileAdded} />
                                </div>}
                        </div>
                        {/*end::Card body*/}
                    </div>
                </div>
            </div>
        </>
    )
})
