import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DeliveryCost, ShopData, TypeOfDelivery } from './checkoutModel';
interface Props {
    deliveryMethod?: TypeOfDelivery
    setDeliveryMethod(value: TypeOfDelivery): void
    shopData: ShopData
    deliveryCost: DeliveryCost
}
export default observer(function DeliveryMethod({ deliveryMethod, setDeliveryMethod, shopData, deliveryCost }: Props) {

    const [model, setModel] = useState(deliveryMethod);

    const handleModelChange = (value: TypeOfDelivery) => {
        setDeliveryMethod(value);
        setModel(value);
    }
    return (
        <>
            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 w-xl-50">
                <div className="d-flex flex-column gap-7 gap-lg-10 w-100">
                    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                        {/*begin::Card header*/}
                        <div className="card-header">
                            <div className="card-title">
                                <h2>Wybierz formę dostawy</h2>
                            </div>
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-0">
                            <div className="row mt-0">
                                {/*begin::Input group*/}
                                <div className="mb-0 fv-row">
                                    {/*begin::Label*/}
                                    {/* <label className="d-flex align-items-center form-label mb-5">Możemy dostarczyć do Ciebie na następujące sposoby:
                                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Przy Otwartej Paczce za dostawę płacisz tylko raz"></i></label> */}
                                    {/*end::Label*/}
                                    {/*begin::Options*/}
                                    <div className="mb-0">                                        
                                        {/*begin:Option*/}
                                        <label className="d-flex flex-stack mb-5 cursor-pointer" onClick={() => handleModelChange(TypeOfDelivery.DPD)}>
                                            {/*begin:Label*/}
                                            <span className="d-flex align-items-center me-2">
                                                {/*begin::Icon*/}
                                                <span className="symbol symbol-75px me-6">
                                                    <span className="symbol-label">
                                                        {/*begin::Svg Icon | path: icons/duotune/graphs/gra008.svg*/}
                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-600">
                                                            <img src="/customer-custom/dpd_logo.svg" alt='dpd' className='w-50px' />
                                                        </span>
                                                        {/*end::Svg Icon*/}
                                                    </span>
                                                </span>
                                                {/*end::Icon*/}
                                                {/*begin::Description*/}
                                                <span className="d-flex flex-column">
                                                    <span className="fw-bolder text-gray-800 text-hover-primary fs-5">Kurier DPD - {deliveryCost.DPD} zł</span>
                                                    <span className="fs-6 fw-bold text-muted">Czas dostawy około 3 dni roboczych</span>
                                                </span>
                                                {/*end:Description*/}
                                            </span>
                                            {/*end:Label*/}
                                            {/*begin:Input*/}
                                            <span className="form-check form-check-custom form-check-solid">
                                                <input className="form-check-input" type="radio" checked={model === TypeOfDelivery.DPD} onChange={() => { }} />
                                            </span>
                                            {/*end:Input*/}
                                        </label>
                                        {/*end::Option*/}
                                        {/*begin:Option*/}
                                        <label className="d-flex flex-stack mb-5 cursor-pointer" onClick={() => handleModelChange(TypeOfDelivery.InPost)}>
                                            {/*begin:Label*/}
                                            <span className="d-flex align-items-center me-2">
                                                {/*begin::Icon*/}
                                                <span className="symbol symbol-75px me-6">
                                                    <span className="symbol-label">
                                                        {/*begin::Svg Icon | path: icons/duotune/graphs/gra008.svg*/}
                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-600">
                                                            <img src="/customer-custom/inpost_logo.svg" alt='inpost' className='w-50px' />
                                                        </span>
                                                        {/*end::Svg Icon*/}
                                                    </span>
                                                </span>
                                                {/*end::Icon*/}
                                                {/*begin::Description*/}
                                                <span className="d-flex flex-column">
                                                    <span className="fw-bolder text-gray-800 text-hover-primary fs-5">Paczkomat InPost - {deliveryCost.InPost} zł</span>
                                                    <span className="fs-6 fw-bold text-muted">Czas dostawy około 4 dni roboczych</span>
                                                </span>
                                                {/*end:Description*/}
                                            </span>
                                            {/*end:Label*/}
                                            {/*begin:Input*/}
                                            <span className="form-check form-check-custom form-check-solid">
                                                <input className="form-check-input" type="radio" checked={model === TypeOfDelivery.InPost} onChange={() => { }} />
                                            </span>
                                            {/*end:Input*/}
                                        </label>
                                        {/*end::Option*/}
                                        {/*begin:Option*/}
                                        <label className="d-flex flex-stack mb-0 cursor-pointer" onClick={() => handleModelChange(TypeOfDelivery.Pickup)}>
                                            {/*begin:Label*/}
                                            <span className="d-flex align-items-center me-2">
                                                {/*begin::Icon*/}
                                                <span className="symbol symbol-75px me-6">
                                                    <span className="symbol-label">
                                                        {/*begin::Svg Icon | path: icons/duotune/finance/fin001.svg*/}
                                                        <span className="svg-icon svg-icon-3x svg-icon-danger">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.3" d="M18 10V20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20V10H18Z" fill="black" />
                                                                <path opacity="0.3" d="M11 10V17H6V10H4V20C4 20.6 4.4 21 5 21H12C12.6 21 13 20.6 13 20V10H11Z" fill="black" />
                                                                <path opacity="0.3" d="M10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10H10Z" fill="black" />
                                                                <path opacity="0.3" d="M18 10C18 11.1 17.1 12 16 12C14.9 12 14 11.1 14 10H18Z" fill="black" />
                                                                <path opacity="0.3" d="M14 4H10V10H14V4Z" fill="black" />
                                                                <path opacity="0.3" d="M17 4H20L22 10H18L17 4Z" fill="black" />
                                                                <path opacity="0.3" d="M7 4H4L2 10H6L7 4Z" fill="black" />
                                                                <path d="M6 10C6 11.1 5.1 12 4 12C2.9 12 2 11.1 2 10H6ZM10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10H10ZM18 10C18 11.1 18.9 12 20 12C21.1 12 22 11.1 22 10H18ZM19 2H5C4.4 2 4 2.4 4 3V4H20V3C20 2.4 19.6 2 19 2ZM12 17C12 16.4 11.6 16 11 16H6C5.4 16 5 16.4 5 17C5 17.6 5.4 18 6 18H11C11.6 18 12 17.6 12 17Z" fill="black" />
                                                            </svg>
                                                        </span>
                                                        {/*end::Svg Icon*/}
                                                    </span>
                                                </span>
                                                {/*end::Icon*/}
                                                {/*begin::Description*/}
                                                <span className="d-flex flex-column">
                                                    <span className="fw-bolder text-gray-800 text-hover-primary fs-5">Odbiór osobisty</span>
                                                    <span className="fs-6 fw-bold text-muted">{shopData.addressLine1} {shopData.addressLine2} <br />({shopData.pickupTime})</span>
                                                </span>
                                                {/*end:Description*/}
                                            </span>
                                            {/*end:Label*/}
                                            {/*begin:Input*/}
                                            <span className="form-check form-check-custom form-check-solid">
                                                <input className="form-check-input" type="radio" checked={model === TypeOfDelivery.Pickup} onChange={() => { }} />
                                            </span>
                                            {/*end:Input*/}
                                        </label>
                                        {/*end::Option*/}
                                    </div>
                                    {/*end::Options*/}
                                </div>
                                {/*end::Input group*/}
                            </div>
                        </div>
                        {/*end::Card body*/}
                    </div>
                </div>
            </div>
        </>
    )
})
