import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CheckoutModel, Product } from './checkoutModel';

interface Props {
    model: CheckoutModel
    setProducts(products: Product[]): void
}


export default observer(function Basket({ model, setProducts: setProductsParent }: Props) {
    const [products, setProducts] = useState(model.products);

    const handleToggleProduct = (product: Product) => {
        let newList = products.map((p) => {
            if (p.id === product.id) {
                const updatedItem = {
                    ...p,
                    isDeleted: !product.isDeleted
                };
                return updatedItem;
            }
            return p;
        });
        if (!newList) newList = [];
        setProducts(newList);
        setProductsParent(newList);
    }

    const getDate = () => {
        var d = new Date();
        d.setDate(d.getDate() - 1);

        return d.toISOString().slice(0, 10) + " 18:30";
    }


    return (
        <>
            {/*begin::Orders*/}
            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 w-xl-50">
                {/*begin::Product List*/}
                <div className="d-flex flex-column gap-7 gap-lg-10 w-100">
                    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                        {/*begin::Card header*/}
                        <div className="card-header">
                            <div className="card-title align-items-start flex-column">
                                <h2>Oto Twój koszyk</h2>
                                <div className="text-muted fw-bold fs-6 pt-1">Koszyk utworzono podczas Live z dnia {getDate()}
                                </div>
                            </div>
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-0">
                            <div className="table-responsive">
                                {/*begin::Table*/}
                                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                    {/*begin::Table head*/}
                                    <thead>
                                        <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                            <th colSpan={2} className="min-w-175px">Produkt</th>
                                            <th className="w-75px text-end">Cena</th>
                                        </tr>
                                    </thead>
                                    {/*end::Table head*/}
                                    {/*begin::Table body*/}
                                    <tbody className="fw-bold text-gray-600">
                                        {products.length > 0 && products.map((p) => (
                                            <tr key={p.id}>
                                                {/*begin::Product*/}
                                                <td>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        <input className="form-check-input" type="checkbox" checked={!p.isDeleted} onChange={() => handleToggleProduct(p)} value="" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        {/*begin::Thumbnail*/}
                                                        <span className="symbol symbol-50px">
                                                            <span className={`symbol-label ${p.isDeleted ? 'opacity-50' : 'opacity-100'}`} style={{ backgroundImage: `url(/assets/media//stock/ecommerce/${p.img})` }} ></span>
                                                        </span>
                                                        {/*end::Thumbnail*/}
                                                        {/*begin::Title*/}
                                                        <div className="ms-5">
                                                            <span className={`fw-bolder ${p.isDeleted ? 'text-gray-500' : 'text-gray-800'}`} >{p.name}</span>
                                                            <div className={`fs-7 ${p.isDeleted ? 'text-gray-400' : 'text-gray-600'}`} >{p.description}</div>
                                                        </div>
                                                        {/*end::Title*/}
                                                    </div>
                                                </td>
                                                {/*end::Product*/}
                                                {/*begin::Total*/}
                                                <td className={`text-end ${p.isDeleted ? '' : 'text-black'}`}>{p.price} zł</td>
                                                {/*end::Total*/}
                                            </tr>
                                        ))}
                                        {/*begin::Shipping*/}
                                        {model.typeOfDelivery !== undefined && 
                                        <tr>
                                            <td colSpan={2} className="text-end">Koszty wysyłki</td>
                                            <td className="text-end">{model.typeOfDelivery !== undefined && model.getDeliveryCost()} zł</td>
                                        </tr>}
                                        {/*end::Shipping*/}
                                        {/*begin::Grand total*/}
                                        <tr>
                                            <td colSpan={2} className="text-end">Suma</td>
                                            <td className="text-dark fs-3 fw-boldest text-end">{model.getSumToPay()} zł</td>
                                        </tr>
                                        {/*end::Grand total*/}
                                    </tbody>
                                    {/*end::Table head*/}
                                </table>
                                {/*end::Table*/}
                            </div>
                            {model.remarks &&
                                <div>
                                    <h4>Twoje Uwagi dodatkowe:</h4>
                                    <div>{model.remarks}</div>
                                </div>}
                        </div>
                        {/*end::Card body*/}
                        <div className="d-flex flex-center" style={{ marginBottom: '-10px' }}>
                            <img src="/assets/media/illustrations/custom/2.png" alt="" className="mw-100 h-150px h-xl-200px" />
                        </div>
                    </div>
                </div>
            </div>
            {/*end::Orders*/}
        </>
    )
})