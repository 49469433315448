const root = '';
const urls = {
    root: () => root + '/',
    basket: () => root + '/',
    redirect: (target: string) => root + `/redirect/${target}`,
    redirectPattern: () => urls.redirect(':target'),
}

export enum RedirectTargets {
    channel = 'channel',
    terms = 'terms'
}

export default urls;