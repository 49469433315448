import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

interface Props {
    paczkomatNumber?: string;
    paczkomatAddress?: string;
    setPaczkomatData(name: string, address: string): void;
}
export default observer(function Paczkomat({ paczkomatNumber, paczkomatAddress, setPaczkomatData }: Props) {

    const startObserving = () => {
        var mutationObserver = new MutationObserver(function (mutations) {
            const paczkomatName = (document.getElementById('paczkomatNumber') as HTMLElement).innerHTML;
            const paczkomatAddress = (document.getElementById('paczkomatAddress') as HTMLElement).innerHTML;
            setPaczkomatData(paczkomatName, paczkomatAddress);
        });
        mutationObserver.observe(document.getElementById('paczkomatData')!, {
            attributes: true,
            characterData: true,
            childList: true,
            subtree: true,
        });
    }

    useEffect(() => {
        startObserving();
        const script1 = loadScript('/customer-custom/paczkomat.js');
        const script2 = loadScript('https://geowidget.easypack24.net/js/sdk-for-javascript.js');

        document.body.appendChild(script1);
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        }
    }, []);

    const loadScript = (src: string) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        return script;
    }

    return (
        <>
            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 w-100 h-100">
                <div className="d-flex flex-column gap-7 gap-lg-10 w-100">
                    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                        {/*begin::Card header*/}
                        <div className="card-header">
                            <div className="card-title">
                                <h2>Wybierz swój paczkomat</h2>
                            </div>
                        </div>
                        {/*end::Card header*/}
                        {/*begin::Card body*/}
                        <div className="card-body pt-0">
                            <div className="row mt-10">
                                {/*begin::Input group*/}
                                <div className="mb-0 fv-row">
                                    <div id="easypack-map"></div>
                                </div>
                                {/*end::Input group*/}
                            </div>
                            <div className="row mt-10">
                                {/*begin::Input group*/}
                                <div id="paczkomatData" className="mb-0 mt-10 fv-row">
                                    <h2>Wybrany paczkomat:</h2>
                                    <h2 id="paczkomatNumber">{paczkomatNumber}</h2>
                                    <h2 id="paczkomatAddress">{paczkomatAddress}</h2>
                                </div>
                                {/*end::Input group*/}
                            </div>
                        </div>
                        {/*end::Card body*/}
                    </div>
                </div>
            </div>
        </>
    )
})
