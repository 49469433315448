import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import OpenParcel from './OpenParcel';
import { CheckoutModel, Customer, Address, TypeOfDelivery, TypeOfPayment, Product } from './checkoutModel';
import DeliveryMethod from './DeliveryMethod';
import CustomerData from './CustomerData';
import PaymentMethod from './PaymentMethod';
import Paczkomat from './Paczkomat';
import PaymentProcess from './PaymentProcess';
import TransferAndScreenshot from './TransferAndScreenshot';
import FinishSummary from './FinishSummary';
import Basket from './Basket';
import { NavLink } from 'react-router-dom';
import urls, { RedirectTargets } from '../../app/common/urls';

export default observer(function BasketPage() {

    const [model, setModel] = useState<CheckoutModel>(new CheckoutModel())

    const [currentStep, setCurrentStep] = useState('Basket');
    const [currentStepNumber, setCurrentStepNumber] = useState(1);
    const [canGoForward, setCanGoForward] = useState(true);

    useEffect(() => {
        checkCanGoForward();
    }, [currentStep, model]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentStep]);

    const handleNext = () => {
        if (currentStep === 'Paczkomat') {
            const paczkomatName = (document.getElementById('paczkomatNumber') as HTMLElement).innerHTML;
            const paczkomatAddress = (document.getElementById('paczkomatAddress') as HTMLElement).innerHTML;
            model.paczkomatNumber = paczkomatName;
            model.paczkomatAddress = paczkomatAddress;
            setModel(model);
        }

        switch (currentStep) {
            case 'Basket': setCurrentStep('OpenParcel'); break;
            case 'OpenParcel': setCurrentStep('DeliveryMethod'); break;
            case 'DeliveryMethod':
                switch (model.typeOfDelivery) {
                    case TypeOfDelivery.InPost: setCurrentStep('Paczkomat'); break;
                    default: setCurrentStep('CustomerData'); break;
                }
                setCurrentStepNumber(2);
                break;
            case 'Paczkomat': setCurrentStep('CustomerData'); break;
            case 'CustomerData': setCurrentStep('PaymentMethod'); break;
            case 'PaymentMethod':
                switch (model.typeOfPayment) {
                    case TypeOfPayment.AtPickup: setCurrentStep('FinishSummary'); setCurrentStepNumber(3); break;
                    case TypeOfPayment.Blik: setCurrentStep('PaymentProcess'); break;
                    case TypeOfPayment.Transfer: setCurrentStep('TransferAndScreenshot'); break;
                }
                break;
            case 'PaymentProcess': setCurrentStep('FinishSummary'); setCurrentStepNumber(3); break;
            case 'TransferAndScreenshot': setCurrentStep('FinishSummary'); setCurrentStepNumber(3); break;
        }
    }

    const handleBack = () => {
        setCurrentStepNumber(1);
        switch (currentStep) {
            case 'FinishSummary': setCurrentStep('PaymentMethod'); //todo: usunac
                break;
            case 'TransferAndScreenshot':
            case 'PaymentProcess': setCurrentStep('PaymentMethod'); break;
            case 'PaymentMethod': setCurrentStep('CustomerData'); break;
            case 'CustomerData':
                switch (model.typeOfDelivery) {
                    case TypeOfDelivery.InPost: setCurrentStep('Paczkomat'); break;
                    default: setCurrentStep('DeliveryMethod'); break;
                }break;

            case 'DeliveryMethod': setCurrentStep('OpenParcel'); break;

            case 'OpenParcel': setCurrentStep('Basket'); break;
            case 'Paczkomat': setCurrentStep('DeliveryMethod'); break;
        }
    }

    const canGoBack = () => {
        switch (currentStep) {
            case 'Basket':
            case 'FinishSummary': return false;
        }
        return true;
    }

    const checkCanGoForward = () => {
        if (!model.products?.find(x => !x.isDeleted)) {
            setCanGoForward(false);
            return;
        }
        switch (currentStep) {
            case 'DeliveryMethod': setCanGoForward(model.typeOfDelivery !== undefined); return;
            case 'CustomerData':
                setCanGoForward(model.customer.isValid());
                if (model.typeOfDelivery === TypeOfDelivery.DPD) setCanGoForward(model.deliveryAddress.isValid());
                return;
            case 'Paczkomat': setCanGoForward(model.paczkomatNumber !== undefined && model.paczkomatNumber !== ''); return;
            case 'PaymentMethod': setCanGoForward(model.typeOfPayment !== undefined); return;
        }
        setCanGoForward(true);
    }

    const setOpenParcel = (value: boolean) => {
        model.openParcel = value;
        setModel(model);
    }

    const setDeliveryMethod = (value: TypeOfDelivery) => {
        model.typeOfDelivery = value;
        if (value !== TypeOfDelivery.Pickup && model.typeOfPayment === TypeOfPayment.AtPickup) {
            model.typeOfPayment = undefined;
        }

        setModel(model);
        checkCanGoForward();
    }

    const setPaymentMethod = (value: TypeOfPayment) => {
        model.typeOfPayment = value;
        setModel(model);
        checkCanGoForward();
    }

    const setAddress = (value: Address) => {
        model.deliveryAddress = value;
        setModel(model);
        checkCanGoForward();
    }

    const setCustomer = (value: Customer) => {
        model.customer = value;
        setModel(model);
        checkCanGoForward();
    }

    const setRemarks = (value: string) => {
        model.remarks = value;
        setModel(model);
    }

    const setRememberData = (value: boolean) => {
        model.rememberData = value;
        setModel(model);
    }

    const setProducts = (value: Product[]) => {
        model.products = value;
        setModel(model);
        checkCanGoForward();
    }

    const setPaczkomatData = (name: string, address: string) => {
        model.paczkomatNumber = name;
        model.paczkomatAddress = address;
        setModel(model);
        checkCanGoForward();
    }

    const setScreenshotName = (value: string) => {
        model.transferScreenshotName = value;
        setModel(model);
    }

    return (
        <>
            {/*begin::Post*/}
            <div className="content flex-column w-100 flex-center" id="kt_content">
                {/*begin::Order details page*/}
                <div className="d-flex flex-column gap-7 gap-lg-10 flex-center">
                    <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 w-100 flex-center">
                        <div className="d-flex align-items-center mb-3 w-50" data-kt-password-meter-control="highlight">
                            <div className={`flex-grow-1 bg-secondary bg-active-warning rounded h-5px me-2 ${currentStepNumber >= 1 ? 'active' : ''}`}></div>
                            <div className={`flex-grow-1 bg-secondary bg-active-warning rounded h-5px me-2 ${currentStepNumber >= 2 ? 'active' : ''}`}></div>
                            <div className={`flex-grow-1 bg-secondary bg-active-warning rounded h-5px  ${currentStepNumber >= 3 ? 'active' : ''}`}></div>
                        </div>
                    </div>

                    {currentStep === 'Basket' &&
                        <Basket model={model} setProducts={setProducts} />}

                    {currentStep === 'OpenParcel' &&
                        <OpenParcel setOpenParcel={setOpenParcel} openParcel={model.openParcel} />}

                    {currentStep === 'DeliveryMethod' &&
                        <DeliveryMethod setDeliveryMethod={setDeliveryMethod} deliveryMethod={model.typeOfDelivery} shopData={model.shopData} deliveryCost={model.deliveryCost} />}

                    {currentStep === 'CustomerData' &&
                        <CustomerData setAddress={setAddress} setCustomer={setCustomer} customer={model.customer} address={model.deliveryAddress!} simplified={model.typeOfDelivery !== TypeOfDelivery.DPD} remarks={model.remarks} setRemarks={setRemarks} setRememberData={setRememberData} rememberData={model.rememberData} />}

                    {currentStep === 'Paczkomat' &&
                        <Paczkomat paczkomatNumber={model.paczkomatNumber} paczkomatAddress={model.paczkomatAddress} setPaczkomatData={setPaczkomatData} />}

                    {currentStep === 'PaymentMethod' &&
                        <PaymentMethod setPaymentMethod={setPaymentMethod} paymentMethod={model.typeOfPayment} paymentAtPickupAvailable={model.typeOfDelivery === TypeOfDelivery.Pickup} accountNumber={model.shopData.accountNumber} />}

                    {currentStep === 'PaymentProcess' &&
                        <PaymentProcess />}

                    {currentStep === 'TransferAndScreenshot' &&
                        <TransferAndScreenshot checkoutModel={model} setScreenshotName={setScreenshotName} />}

                    {currentStep === 'FinishSummary' &&
                        <FinishSummary model={model} setProducts={setProducts} />}

                    <div className="d-flex flex-row gap-7 gap-lg-10">
                        <div className="py-4 d-flex flex-stack flex-row-fluid overflow-hidden text-align-right">
                            <div>
                                {canGoBack() &&
                                    <button type="button" className="btn btn-lg btn-light-info me-3" onClick={() => handleBack()}>
                                        <span className="svg-icon svg-icon-4 me-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black"></rect>
                                                <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black"></path>
                                            </svg>
                                        </span>
                                        Wstecz</button>}
                            </div>
                            <div>
                                {currentStep !== 'FinishSummary' &&
                                    <button type="button" className="btn btn-lg btn-info" disabled={!canGoForward} onClick={() => handleNext()}>Kontynuuj zakupy
                                        <span className="svg-icon svg-icon-4 ms-1 me-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                                                <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                                            </svg>
                                        </span>
                                    </button>}
                                {currentStep === 'FinishSummary' &&
                                    <NavLink to={urls.redirect(RedirectTargets.channel)} className="btn btn-lg btn-info" >Wróć do Transmisji
                                        <span className="svg-icon svg-icon-4 ms-1 me-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                                                <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                                            </svg>
                                        </span>
                                    </NavLink>}
                            </div>
                        </div>
                    </div>
                </div>
                {/*end::Order details page*/}
            </div>
            {/*end::Post*/}
        </>
    )
})
